<template>
  <div class="d-flex flex-column align-items-center">
    <div class="d-flex flex-column main-container col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3">
      <div class="d-flex flex-row justify-content-around col-12 mt-3">
        <button @click="toggleHowToPlay(true)" :class="`col-4 ${fantasyHowToPlay ? 'active-button' : 'inactive-button'}`">Fantasy Football</button>
        <button @click="toggleHowToPlay(false)" :class="`col-4 ${!fantasyHowToPlay ? 'active-button' : 'inactive-button'}`">Score Predictor</button>
      </div>
      <div class="d-flex flex-column mt-2 p-3 how-to-play-header-text">
        How To Play:
      </div>
      <div v-if="!fantasyHowToPlay" class="d-flex flex-column align-items-center col-12 mt-1 mb-5">
        <div class="d-flex flex-column col-11">
          <div @click="toggleDropdown(0)" class="d-flex flex-row how-to-play-topics">
            <div class="col-10 topic-header-text">General</div>
            <div v-if="!isDropdownOpen(0) " class="d-flex flex-row justify-content-center col-2 plus-icon">+</div>
            <div v-if="isDropdownOpen(0)" class="d-flex flex-row justify-content-center col-2 plus-icon">-</div>
          </div>
          <div v-if="isDropdownOpen(0)" class="pt-2 how-to-play-info-box">
            <div class="p-2">1. Fanclash Contests is a game of skill and require some skill and knowledge of professional sports information as well as the Fanclash rules from You to accumulate
              predictor points;
            </div>
            <div class="p-2">2. Predictor points are accumulated through the performance of real-life teams across multiple sporting disciplines and leagues;</div>
          </div>

          <div @click="toggleDropdown(1)" class="d-flex flex-row how-to-play-topics mt-2">
            <div class="col-10 topic-header-text">Editing a Round Prediction</div>
            <div v-if="!isDropdownOpen(1) " class="d-flex flex-row justify-content-center col-2 plus-icon">+</div>
            <div v-if="isDropdownOpen(1)" class="d-flex flex-row justify-content-center col-2 plus-icon">-</div>
          </div>
          <div v-if="isDropdownOpen(1)" class="pt-2 how-to-play-info-box">
            <div class="p-2">You can edit your Round Predictions at any point up to the start of a Contest by clicking on the edit option on the entry view. HOWEVER, once a Contest starts, your
              predictions will no longer be editable.
            </div>
          </div>

          <div @click="toggleDropdown(2)" class="d-flex flex-row how-to-play-topics mt-2">
            <div class="col-10 topic-header-text">Entry Fees</div>
            <div v-if="!isDropdownOpen(2) " class="d-flex flex-row justify-content-center col-2 plus-icon">+</div>
            <div v-if="isDropdownOpen(2)" class="d-flex flex-row justify-content-center col-2 plus-icon">-</div>
          </div>
          <div v-if="isDropdownOpen(2)" class="pt-2 how-to-play-info-box">
            <div class="p-2">There are no entry fees for the specific contests. Members of the Fanclash service will have the ability to enter into weekly and daily contests as long as their
              subscription to the content is active.
            </div>
          </div>

          <div @click="toggleDropdown(3)" class="d-flex flex-row how-to-play-topics mt-2">
            <div class="col-10 topic-header-text">Entry Cancellation</div>
            <div v-if="!isDropdownOpen(3) " class="d-flex flex-row justify-content-center col-2 plus-icon">+</div>
            <div v-if="isDropdownOpen(3)" class="d-flex flex-row justify-content-center col-2 plus-icon">-</div>
          </div>
          <div v-if="isDropdownOpen(3)" class="pt-2 how-to-play-info-box">
            <div class="p-2">Fanclash reserves the right to cancel any Game before it is scheduled to begin. When multiple Games with the same entry terms (such as but not limited to real–life games,
              anticipated pool size etc) are subject to cancellation due to the number of Contestants participating being too low, Fanclash may avoid cancellation by combining the player pools for
              those Games. HOWEVER Fanclash will never merge player pools with different entry terms;
            </div>
          </div>

          <div @click="toggleDropdown(4)" class="d-flex flex-row how-to-play-topics mt-2">
            <div class="col-10 topic-header-text">Cancelling Contests</div>
            <div v-if="!isDropdownOpen(4) " class="d-flex flex-row justify-content-center col-2 plus-icon">+</div>
            <div v-if="isDropdownOpen(4)" class="d-flex flex-row justify-content-center col-2 plus-icon">-</div>
          </div>
          <div v-if="isDropdownOpen(4)" class="pt-2 how-to-play-info-box">
            <div class="p-2">Fanclash reserves the right to cancel Contests at its discretion, without any restrictions. This would typically only be done where we believe there would be a widespread
              impact on the integrity of Contests due to problems on the site or real-life events impacting on the Contests.
            </div>
          </div>

          <div @click="toggleDropdown(5)" class="d-flex flex-row how-to-play-topics mt-2">
            <div class="col-10 topic-header-text">Contest Term</div>
            <div v-if="!isDropdownOpen(5) " class="d-flex flex-row justify-content-center col-2 plus-icon">+</div>
            <div v-if="isDropdownOpen(5)" class="d-flex flex-row justify-content-center col-2 plus-icon">-</div>
          </div>
          <div v-if="isDropdownOpen(5)" class="pt-2 how-to-play-info-box">
            <div class="p-2">Contests will take place on a single day, multiple days or longer as specified in each Contest’s rules. Winners are determined by the criteria stated in the game rules.
            </div>
          </div>

          <div @click="toggleDropdown(6)" class="d-flex flex-row how-to-play-topics mt-2">
            <div class="col-10 topic-header-text">Cancellations & Postponements</div>
            <div v-if="!isDropdownOpen(6) " class="d-flex flex-row justify-content-center col-2 plus-icon">+</div>
            <div v-if="isDropdownOpen(6)" class="d-flex flex-row justify-content-center col-2 plus-icon">-</div>
          </div>
          <div v-if="isDropdownOpen(6)" class="pt-2 how-to-play-info-box">
            <div class="p-2">In the event that a scheduled real-life game is cancelled, moved to an earlier date (“pre-empted”), or postponed for any reason, no points will be awarded for players in
              the cancelled, pre-empted, or postponed game or event.
            </div>
          </div>

          <div @click="toggleDropdown(7)" class="d-flex flex-row how-to-play-topics mt-2">
            <div class="col-10 topic-header-text">Prizes</div>
            <div v-if="!isDropdownOpen(7) " class="d-flex flex-row justify-content-center col-2 plus-icon">+</div>
            <div v-if="isDropdownOpen(7)" class="d-flex flex-row justify-content-center col-2 plus-icon">-</div>
          </div>
          <div v-if="isDropdownOpen(7)" class="pt-2 how-to-play-info-box">
            <div class="p-2">1. Fanclash offers many different types of Contests. For each one, Fanclash announces the prizes on offer at the start of the contest;
            </div>
            <div class="p-2">2. Prizes are not subject to change based on the number of Contestants or total entry fees for that Contest;
            </div>
            <div class="p-2">3. Prizes are awarded to Contestants who accumulate the most fantasy points as detailed in the Contest details, after the particular Contest has ended. Fanclash will never
              determine winners based on the score, point-spread, or performance of any real-life team or combination of teams;
            </div>
            <div class="p-2">4. When a Contestant wins a Contest, the Prize will be awarded directly to the customer in the form of airtime. With prizes other than airtime customers will be contacted
              in person in accordance with the terms and condition attached to that specific prize.
            </div>
            <div class="p-2">5. Fanclash may offer “free” game play for entertainment purposes, without the possibilities of a Prize or Prizes being awarded. This will be clearly indicated at the
              outset;
            </div>
            <div class="p-2">6. Prize calculations are based on Contests’ results as at the time when Fanclash charts final point scoring.</div>
            <div class="p-2">7. Points scored are based on the statistics from the data feed provided by our third party statistics provider (“Statistics Provider”). Although Fanclash uses its best
              endeavours to ensure a good reputable Service, it is solely reliant on the information provided by the Statistics Provider and is in no way responsible for faulty data received from the
              Statistics Provider.
            </div>
            <div class="p-2">8. You hereby indemnify Fanclash against any and all claims, arising directly or indirectly due to faulty data received by Fanclash from its Statistics Provider;
            </div>
            <div class="p-2">9. Fanclash waits for a certain period to elapse, to confirm the winner(s) of a Contest after the final game in the Contest is completed so that the Statistics Provider
              can correct any major errors in its system first;
            </div>
            <div class="p-2">10. Note that once Prizes are awarded to the winners, scoring results will not be changed regardless of any official adjustments made by professional sports leagues.
            </div>
            <div class="p-2">11. This is not the same situation as a case where a correction needs to be made after settlement, due to a problem with the settlement process. In these cases, the
              settlement process (including prize awarding or airtime provisioning) may be reversed and games resettled correctly.
            </div>
            <div class="p-2">12. Prizes may not be substituted or transferred. All taxes associated with the receipt or use of any Prize is the sole responsibility of the winner. If any legal
              authority Contests Prize awards, Fanclash may, in its sole discretion, determine whether or not to award such Prizes.
            </div>
            <div class="p-2">13. Ties
              <div class="p-2">
                1. If there is a tie/draw in a specific Contest among Fanclash users, the Prize will be divided equally between all tied players;
              </div>
              <div class="p-2">
                2. In the event of a tie between players where the Prize(s) cannot be equally split the entry with the highest individually scoring athlete will be used as a tiebreaker, proceeding to
                the second-highest, third-highest, etc. player as needed to break the tie. In the event the tie remains unbroken, the player who submitted their team first will be declared the winner.
              </div>
            </div>
          </div>

          <div @click="toggleDropdown(8)" class="d-flex flex-row how-to-play-topics mt-2">
            <div class="col-10 topic-header-text">Notifications</div>
            <div v-if="!isDropdownOpen(8) " class="d-flex flex-row justify-content-center col-2 plus-icon">+</div>
            <div v-if="isDropdownOpen(8)" class="d-flex flex-row justify-content-center col-2 plus-icon">-</div>
          </div>
          <div v-if="isDropdownOpen(8)" class="pt-2 how-to-play-info-box">
            <div class="p-2">Winners are generally advised by SMS within one business day. You hereby agree to Your Username and country location being published in the event that You are a winner.
              Winners may be asked to return via e-mail or regular mail an affidavit of eligibility and appropriate tax forms. A winner’s failure to comply with such requests can result in
              disqualification. Any Prize notification returned as undeliverable may result in disqualification and selection of an alternate winner. A list of winners for each competition period may
              be obtained by written request to: Fanclash: hello@thefanclash.com
            </div>
          </div>

          <div @click="toggleDropdown(9)" class="d-flex flex-row how-to-play-topics mt-2">
            <div class="col-10 topic-header-text">Point Scoring</div>
            <div v-if="!isDropdownOpen(9) " class="d-flex flex-row justify-content-center col-2 plus-icon">+</div>
            <div v-if="isDropdownOpen(9)" class="d-flex flex-row justify-content-center col-2 plus-icon">-</div>
          </div>
          <div v-if="isDropdownOpen(9)" class="pt-2 how-to-play-info-box">
            <div class="p-2 scoring-system-header">Predictor Scoring System
            </div>
            <div class="d-flex flex-row p-2">
              <div class="d-flex flex-row justify-content-start col-6">
                Description
              </div>
              <div class="d-flex flex-row justify-content-end col-6">
                Points
              </div>
            </div>
            <div class="d-flex flex-row p-2">
              <div class="d-flex flex-row justify-content-start col-7">
                Home score
              </div>
              <div class="d-flex flex-row justify-content-end col-5">
                6
              </div>
            </div>
            <div class="d-flex flex-row p-2">
              <div class="d-flex flex-row justify-content-start col-7">
                Away score
              </div>
              <div class="d-flex flex-row justify-content-end col-5">
                6
              </div>
            </div>
            <div class="d-flex flex-row p-2">
              <div class="d-flex flex-row justify-content-start col-7">
                Result
              </div>
              <div class="d-flex flex-row justify-content-end col-5">
                10
              </div>
            </div>
            <div class="d-flex flex-row p-2">
              <div class="d-flex flex-row justify-content-start col-7">
                All correct
              </div>
              <div class="d-flex flex-row justify-content-end col-5">
                10
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="fantasyHowToPlay" class="d-flex flex-column align-items-center col-12 mt-1">
        <div class="d-flex flex-column col-11">
          <div @click="toggleDropdown(0)" class="d-flex flex-row how-to-play-topics">
            <div class="col-10 topic-header-text">General</div>
            <div v-if="!isDropdownOpen(0) " class="d-flex flex-row justify-content-center col-2 plus-icon">+</div>
            <div v-if="isDropdownOpen(0)" class="d-flex flex-row justify-content-center col-2 plus-icon">-</div>
          </div>
          <div v-if="isDropdownOpen(0)" class="pt-2 how-to-play-info-box">
            <div class="p-2">1. Fanclash Contests is a game of skill and require some skill and knowledge of professional sports information as well as the Fanclash rules from You to accumulate
              fantasy points;
            </div>
            <div class="p-2">2. Fantasy points are accumulated through the performance of real-life athletes across multiple sporting matches;</div>
            <div class="p-2">3. Each fantasy line-up must include real-life athletes, so that each entry into a Fanclash Contest shall be made up of players drawn from a minimum of one real-life
              sporting matches.
            </div>
            <div class="p-2">4. Fanclash strictly prohibits the winning of any Contest based solely on the single performance of an individual athlete;</div>
            <div class="p-2">5. The site will automatically prevent You from saving a line-up that is in violation of any of these restrictions;</div>
            <div class="p-2">6. There will be no trading of players, no extra moves and no replacing injured or benched players;</div>
          </div>

          <div @click="toggleDropdown(1)" class="d-flex flex-row how-to-play-topics mt-2">
            <div class="col-10 topic-header-text">Contest Rules</div>
            <div v-if="!isDropdownOpen(1) " class="d-flex flex-row justify-content-center col-2 plus-icon">+</div>
            <div v-if="isDropdownOpen(1)" class="d-flex flex-row justify-content-center col-2 plus-icon">-</div>
          </div>
          <div v-if="isDropdownOpen(1)" class="pt-2 how-to-play-info-box">
            <div class="p-2">1. Each Contest requires selecting one striker, two midfielders, one defensive unit (consisting of players listed as defensive players by the stats provider in a specific
              team) and a goal keeper.
            </div>
            <div class="p-2">2. The player pool consists of players that the Statistics Provider has statistics for in that particular league and determines to be eligible for the matches included in
              the Contest;
            </div>
            <div class="p-2">3. Any players left off any squad by the Statistics Provider (“missing” players) will only be added once the Statistics Provider recognizes and/or adds the new player and
              Fanclash shall not be liable in any way for such player not being featured in any Contest;
            </div>
            <div class="p-2">4. Once a Contest starts, player salaries will not change, however it can fluctuate from Contest to Contest based on the player’s performance and popularity;</div>
            <div class="p-2">5. Each Contest specifies a date after which entries may not be added, edited, or cancelled;</div>
            <div class="p-2">6. Contest results and winners will be based on the total points scored across each entrant’s squad (scoring summarized below) during the duration of the Contest;</div>
            <div class="p-2">7. If a match that is part of a Contest is rescheduled outside of the originally scheduled beginning and end of the Contest, players in those matches will earn zero
              points.
            </div>
          </div>

          <div @click="toggleDropdown(2)" class="d-flex flex-row how-to-play-topics mt-2">
            <div class="col-10 topic-header-text">Editing a Line-up</div>
            <div v-if="!isDropdownOpen(2) " class="d-flex flex-row justify-content-center col-2 plus-icon">+</div>
            <div v-if="isDropdownOpen(2)" class="d-flex flex-row justify-content-center col-2 plus-icon">-</div>
          </div>
          <div v-if="isDropdownOpen(2)" class="pt-2 how-to-play-info-box">
            <div class="p-2">You can edit Your line-up at any point up to the start of a Contest by clicking on the edit option on the entry view. HOWEVER, once a Contest starts, your line-up will no
              longer be editable and you will be able to see your opponents’ line-up.
            </div>
          </div>

          <div @click="toggleDropdown(3)" class="d-flex flex-row how-to-play-topics mt-2">
            <div class="col-10 topic-header-text">Entry Fees</div>
            <div v-if="!isDropdownOpen(3) " class="d-flex flex-row justify-content-center col-2 plus-icon">+</div>
            <div v-if="isDropdownOpen(3)" class="d-flex flex-row justify-content-center col-2 plus-icon">-</div>
          </div>
          <div v-if="isDropdownOpen(3)" class="pt-2 how-to-play-info-box">
            <div class="p-2">There are no entry fees for the specific contests. Members of the Fanclash service will have the ability to enter into weekly and daily contests as long as their
              subscription to the content is active.
            </div>
          </div>

          <div @click="toggleDropdown(4)" class="d-flex flex-row how-to-play-topics mt-2">
            <div class="col-10 topic-header-text">Entry Cancellation</div>
            <div v-if="!isDropdownOpen(4) " class="d-flex flex-row justify-content-center col-2 plus-icon">+</div>
            <div v-if="isDropdownOpen(4)" class="d-flex flex-row justify-content-center col-2 plus-icon">-</div>
          </div>
          <div v-if="isDropdownOpen(4)" class="pt-2 how-to-play-info-box">
            <div class="p-2">Fanclash reserves the right to cancel any Game before it is scheduled to begin. When multiple Games with the same entry terms (such as but not limited to real–life games,
              anticipated pool size etc) are subject to cancellation due to the number of Contestants participating being too low, Fanclash may avoid cancellation by combining the player pools for
              those Games. HOWEVER Fanclash will never merge player pools with different entry terms;
            </div>
          </div>

          <div @click="toggleDropdown(5)" class="d-flex flex-row how-to-play-topics mt-2">
            <div class="col-10 topic-header-text">Cancelling Contests</div>
            <div v-if="!isDropdownOpen(5) " class="d-flex flex-row justify-content-center col-2 plus-icon">+</div>
            <div v-if="isDropdownOpen(5)" class="d-flex flex-row justify-content-center col-2 plus-icon">-</div>
          </div>
          <div v-if="isDropdownOpen(5)" class="pt-2 how-to-play-info-box">
            <div class="p-2">Fanclash reserves the right to cancel Contests at its discretion, without any restrictions. This would typically only be done where we believe there would be a widespread
              impact on the integrity of Contests due to problems on the site or real-life events impacting on the Contests.
            </div>
          </div>

          <div @click="toggleDropdown(6)" class="d-flex flex-row how-to-play-topics mt-2">
            <div class="col-10 topic-header-text">Contest Term</div>
            <div v-if="!isDropdownOpen(6) " class="d-flex flex-row justify-content-center col-2 plus-icon">+</div>
            <div v-if="isDropdownOpen(6)" class="d-flex flex-row justify-content-center col-2 plus-icon">-</div>
          </div>
          <div v-if="isDropdownOpen(6)" class="pt-2 how-to-play-info-box">
            <div class="p-2">Contests will take place on a single day, multiple days or longer as specified in each Contest’s rules. Winners are determined by the criteria stated in the game rules.
            </div>
          </div>

          <div @click="toggleDropdown(7)" class="d-flex flex-row how-to-play-topics mt-2">
            <div class="col-10 topic-header-text">Squads</div>
            <div v-if="!isDropdownOpen(7) " class="d-flex flex-row justify-content-center col-2 plus-icon">+</div>
            <div v-if="isDropdownOpen(7)" class="d-flex flex-row justify-content-center col-2 plus-icon">-</div>
          </div>
          <div v-if="isDropdownOpen(7)" class="pt-2 how-to-play-info-box">
            <div class="p-2">1. Squads will consist of 11 (eleven) players or less dependent on the number of players in a specific squads defensive unit. Further restrictions may be specified in the
              particular Contest rules.
            </div>
            <div class="p-2">
              2. The positions to choose players in are limited to: Goalkeeper (GK), Defensive Unit (D), Midfielder (MF) and Forward (F). Fanclash makes the exclusive ruling on position eligibility
              and each player is only eligible at ONE of the four positions listed above.
            </div>
          </div>

          <div @click="toggleDropdown(8)" class="d-flex flex-row how-to-play-topics mt-2">
            <div class="col-10 topic-header-text">Cancellations & Postponements</div>
            <div v-if="!isDropdownOpen(8) " class="d-flex flex-row justify-content-center col-2 plus-icon">+</div>
            <div v-if="isDropdownOpen(8)" class="d-flex flex-row justify-content-center col-2 plus-icon">-</div>
          </div>
          <div v-if="isDropdownOpen(8)" class="pt-2 how-to-play-info-box">
            <div class="p-2">In the event that a scheduled real-life game is cancelled, moved to an earlier date (“pre-empted”), or postponed for any reason, no points will be awarded for players in
              the cancelled, pre-empted, or postponed game or event.
            </div>
          </div>

          <div @click="toggleDropdown(9)" class="d-flex flex-row how-to-play-topics mt-2">
            <div class="col-10 topic-header-text">Contest Rules</div>
            <div v-if="!isDropdownOpen(9) " class="d-flex flex-row justify-content-center col-2 plus-icon">+</div>
            <div v-if="isDropdownOpen(9)" class="d-flex flex-row justify-content-center col-2 plus-icon">-</div>
          </div>
          <div v-if="isDropdownOpen(9)" class="pt-2 how-to-play-info-box">
            <div class="p-2">1. Fanclash offers many different types of Contests. For each one, Fanclash announces the prizes on offer at the start of the contest;
            </div>
            <div class="p-2">2. Prizes are not subject to change based on the number of Contestants or total entry fees for that Contest;
            </div>
            <div class="p-2">3. Prizes are awarded to Contestants who accumulate the most fantasy points as detailed in the Contest details, after the particular Contest has ended. Fanclash will never
              determine winners based on the score, point-spread, or performance of any real-life team or combination of teams;
            </div>
            <div class="p-2">4. When a Contestant wins a Contest, the Prize will be awarded directly to the customer in the form of airtime. With prizes other than airtime customers will be contacted
              in person in accordance with the terms and condition attached to that specific prize.
            </div>
            <div class="p-2">5. Fanclash may offer “free” game play for entertainment purposes, without the possibilities of a Prize or Prizes being awarded. This will be clearly indicated at the
              outset;
            </div>
            <div class="p-2">6. Prize calculations are based on Contests’ results as at the time when Fanclash charts final point scoring.</div>
            <div class="p-2">7. Points scored are based on the statistics from the data feed provided by our third party statistics provider (“Statistics Provider”). Although Fanclash uses its best
              endeavours to ensure a good reputable Service, it is solely reliant on the information provided by the Statistics Provider and is in no way responsible for faulty data received from the
              Statistics Provider.
            </div>
            <div class="p-2">8. You hereby indemnify Fanclash against any and all claims, arising directly or indirectly due to faulty data received by Fanclash from its Statistics Provider;
            </div>
            <div class="p-2">9. Fanclash waits for a certain period to elapse, to confirm the winner(s) of a Contest after the final game in the Contest is completed so that the Statistics Provider
              can correct any major errors in its system first;
            </div>
            <div class="p-2">10. Note that once Prizes are awarded to the winners, scoring results will not be changed regardless of any official adjustments made by professional sports leagues.
            </div>
            <div class="p-2">11. This is not the same situation as a case where a correction needs to be made after settlement, due to a problem with the settlement process. In these cases, the
              settlement process (including prize awarding or airtime provisioning) may be reversed and games resettled correctly.
            </div>
            <div class="p-2">12. Prizes may not be substituted or transferred. All taxes associated with the receipt or use of any Prize is the sole responsibility of the winner. If any legal
              authority Contests Prize awards, Fanclash may, in its sole discretion, determine whether or not to award such Prizes.
            </div>
            <div class="p-2">13. Ties
              <div class="p-2">
                1. If there is a tie/draw in a specific Contest among Fanclash users, the Prize will be divided equally between all tied players;
              </div>
              <div class="p-2">
                2. In the event of a tie between players where the Prize(s) cannot be equally split the entry with the highest individually scoring athlete will be used as a tiebreaker, proceeding to
                the second-highest, third-highest, etc. player as needed to break the tie. In the event the tie remains unbroken, the player who submitted their team first will be declared the winner.
              </div>
            </div>
          </div>

          <div @click="toggleDropdown(10)" class="d-flex flex-row how-to-play-topics mt-2">
            <div class="col-10 topic-header-text">Notifications</div>
            <div v-if="!isDropdownOpen(10) " class="d-flex flex-row justify-content-center col-2 plus-icon">+</div>
            <div v-if="isDropdownOpen(10)" class="d-flex flex-row justify-content-center col-2 plus-icon">-</div>
          </div>
          <div v-if="isDropdownOpen(10)" class="pt-2 how-to-play-info-box">
            <div class="p-2">Winners are generally advised by SMS within one business day. You hereby agree to Your Username and country location being published in the event that You are a winner.
              Winners may be asked to return via e-mail or regular mail an affidavit of eligibility and appropriate tax forms. A winner’s failure to comply with such requests can result in
              disqualification. Any Prize notification returned as undeliverable may result in disqualification and selection of an alternate winner. A list of winners for each competition period may
              be obtained by written request to: Fanclash: hello@thefanclash.com
            </div>
          </div>

          <div @click="toggleDropdown(11)" class="d-flex flex-row how-to-play-topics mt-2 mb-5">
            <div class="col-10 topic-header-text">Point Scoring</div>
            <div v-if="!isDropdownOpen(11) " class="d-flex flex-row justify-content-center col-2 plus-icon">+</div>
            <div v-if="isDropdownOpen(11)" class="d-flex flex-row justify-content-center col-2 plus-icon">-</div>
          </div>
          <div v-if="isDropdownOpen(11)" class="pt-2 how-to-play-info-box">
            <div class="p-2 scoring-system-header">Fantasy League Scoring System
            </div>
            <div class="d-flex flex-row p-2">
              <div class="d-flex flex-row justify-content-start col-6">
                Game/Action
              </div>
              <div class="d-flex flex-row justify-content-end col-6">
                Daily Fantasy League
              </div>
            </div>
            <div class="d-flex flex-row p-2">
              <div class="d-flex flex-row justify-content-start col-7">
                Sixty Minutes Played
              </div>
              <div class="d-flex flex-row justify-content-end col-5">
                2 points
              </div>
            </div>
            <div class="p-2 scoring-system-header">
              Attacking
            </div>
            <div class="d-flex flex-row p-2">
              <div class="d-flex flex-row justify-content-start col-7">
                Goal Scored By Forward
              </div>
              <div class="d-flex flex-row justify-content-end col-5">
                4 points
              </div>
            </div>
            <div class="d-flex flex-row p-2">
              <div class="d-flex flex-row justify-content-start col-7">
                Goal Scored By Midfielder
              </div>
              <div class="d-flex flex-row justify-content-end col-5">
                5 points
              </div>
            </div>
            <div class="d-flex flex-row p-2">
              <div class="d-flex flex-row justify-content-start col-7">
                Goal Scored By Defender
              </div>
              <div class="d-flex flex-row justify-content-end col-5">
                6 points
              </div>
            </div>
            <div class="d-flex flex-row p-2">
              <div class="d-flex flex-row justify-content-start col-7">
                Assist
              </div>
              <div class="d-flex flex-row justify-content-end col-5">
                3 points
              </div>
            </div>
            <div class="d-flex flex-row p-2">
              <div class="d-flex flex-row justify-content-start col-7">
                Shot On Target
              </div>
              <div class="d-flex flex-row justify-content-end col-5">
                1 points
              </div>
            </div>
            <div class="d-flex flex-row p-2">
              <div class="d-flex flex-row justify-content-start col-7">
                Penalty Kick Missed/Saved
              </div>
              <div class="d-flex flex-row justify-content-end col-5">
                -2 points
              </div>
            </div>
            <div class="p-2 scoring-system-header">
              Defending/Discipline
            </div>
            <div class="d-flex flex-row p-2">
              <div class="d-flex flex-row justify-content-start col-7">
                Pass Intercepted
              </div>
              <div class="d-flex flex-row justify-content-end col-5">
                1 points
              </div>
            </div>
            <div class="d-flex flex-row p-2">
              <div class="d-flex flex-row justify-content-start col-7">
                Winning Tackle
              </div>
              <div class="d-flex flex-row justify-content-end col-5">
                1 points
              </div>
            </div>
            <div class="d-flex flex-row p-2">
              <div class="d-flex flex-row justify-content-start col-7">
                Clean Sheet (Defenders)
              </div>
              <div class="d-flex flex-row justify-content-end col-5">
                1 points
              </div>
            </div>
            <div class="d-flex flex-row p-2">
              <div class="d-flex flex-row justify-content-start col-7">
                Clean Sheet (Midfielders)
              </div>
              <div class="d-flex flex-row justify-content-end col-5">
                1 points
              </div>
            </div>
            <div class="d-flex flex-row p-2">
              <div class="d-flex flex-row justify-content-start col-7">
                Goal Against (Defenders)
              </div>
              <div class="d-flex flex-row justify-content-end col-5">
                -1 points
              </div>
            </div>
            <div class="d-flex flex-row p-2">
              <div class="d-flex flex-row justify-content-start col-7">
                Own Goal
              </div>
              <div class="d-flex flex-row justify-content-end col-5">
                -2 points
              </div>
            </div>
            <div class="d-flex flex-row p-2">
              <div class="d-flex flex-row justify-content-start col-7">
                Yellow Card
              </div>
              <div class="d-flex flex-row justify-content-end col-5">
                -1 points
              </div>
            </div>
            <div class="d-flex flex-row p-2">
              <div class="d-flex flex-row justify-content-start col-7">
                Red Card
              </div>
              <div class="d-flex flex-row justify-content-end col-5">
                -3 points
              </div>
            </div>
            <div class="p-2 scoring-system-header">
              Goalkeepers
            </div>
            <div class="d-flex flex-row p-2">
              <div class="d-flex flex-row justify-content-start col-7">
                Save
              </div>
              <div class="d-flex flex-row justify-content-end col-5">
                1 points
              </div>
            </div>
            <div class="d-flex flex-row p-2">
              <div class="d-flex flex-row justify-content-start col-7">
                Penalty Kick Save
              </div>
              <div class="d-flex flex-row justify-content-end col-5">
                5 points
              </div>
            </div>
            <div class="d-flex flex-row p-2">
              <div class="d-flex flex-row justify-content-start col-7">
                Clean Sheet
              </div>
              <div class="d-flex flex-row justify-content-end col-5">
                4 points
              </div>
            </div>
            <div class="d-flex flex-row p-2">
              <div class="d-flex flex-row justify-content-start col-7">
                Goal Against
              </div>
              <div class="d-flex flex-row justify-content-end col-5">
                -1 points
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HowToPlay",
  data() {
    return {
      fantasyHowToPlay: true,
      openDropdown: null,
      selectedDropDown: null,
    }
  },
  methods: {
    toggleHowToPlay(boolean) {
      this.fantasyHowToPlay = boolean
      this.selectedDropDown = null
      this.openDropdown = null
    },
    toggleDropdown(dropdown) {
      if (this.openDropdown === dropdown) {
        this.openDropdown = null;
      } else {
        this.openDropdown = dropdown
        this.selectedDropDown = this.openDropdown
      }
    },
    isDropdownOpen(dropdown) {
      return this.openDropdown === dropdown;
    }
  }
}
</script>

<style scoped>
.main-container {
  background-color: #e8e8e8;
}

.active-button, .inactive-button {
  font-size: 15px;
  font-weight: 600;
  border: 0;
  border-radius: 12px;
  height: 35px;
}

.active-button {
  background-color: red;
  color: #FFFFFF;
}

.inactive-button {
  background-color: #adadad;
  color: black;
}

.how-to-play-header-text {
  font-size: 14px;
  font-weight: 600;
  padding-left: 10px;
}

.how-to-play-topics {
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  height: 45px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 700;
}

.topic-header-text {
  padding-left: 22px;
}

.plus-icon {
  font-weight: 600;
  font-size: 30px;
  padding-bottom: 5px;
  color: red;
}

.how-to-play-info-box {
  background-color: #FFFFFF;
  margin-top: -10px;
  border-radius: 0 0 10px 10px;
  padding-left: 15px;
  padding-right: 5px;
  font-size: 12px;
  font-weight: 700;
}

.scoring-system-header {
  text-decoration: underline;
}

@media screen and (max-width: 385px) {
  .active-button, .inactive-button {
    font-size: 13px
  }
}
</style>

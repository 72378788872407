<template>
  <div class="wrapper">
    <div class="d-flex header row justify-content-center">
      <Header/>
    </div>
    <div class="d-flex row justify-content-center content-body">
      <LoadingSpinner class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3" v-if="this.isLoading"/>
      <router-view class=""></router-view>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header"
import LoadingSpinner from "@/components/LoadingSpinner";
import {mapMutations, mapState} from "vuex";
import {environmentConfig} from "@/config/environmentConfig";
import LoginService from "@/services/loginService";
import store from "@/store";

export default {
  components: {Header, LoadingSpinner},
  computed: {
    ...mapState(['isLoading'])
  },
  data() {
    return {
      containerScroll: 'hidden'
    }
  },
  async beforeMount() {
    const autoLoginToken = environmentConfig.appEnv === 'production' ? await LoginService.autoLogin() : undefined;
    const urlParams = new URLSearchParams(window.location.search);
    const encryptedMsisdn = urlParams.get('encryptedMsisdn');
    const vodapayUserId = urlParams.get('vodapayUserId');
    const userToken = urlParams.get('fantasyToken');

    if (encryptedMsisdn) {
      store.commit('setEncryptedMsisdn', encryptedMsisdn);
      const headerEnrichedLoginResponse = await LoginService.headerEnrichedLogin(encryptedMsisdn);
      if (headerEnrichedLoginResponse.data.token) {
        if (headerEnrichedLoginResponse.data.token) {
          const loginResponse = await LoginService.getUserStatus(headerEnrichedLoginResponse.data.token);
          store.commit('setToken', headerEnrichedLoginResponse.data.token);
          store.commit('setUser', loginResponse.data);

          if (loginResponse.data.grantAccess) {
            if (loginResponse.data.username) {
              this.$router.push('/game-selection')
            } else {
              this.$router.push('/create-username')
            }
          } else {
            this.$router.push('/subscribe')
          }
        }
      }
    } else if (vodapayUserId) {
      const userDataResponse = await LoginService.vodapayUserAuth(vodapayUserId);
      if (userDataResponse.data.grantAccess) {
        this.setMiniAppParams(userDataResponse, vodapayUserId);
        store.commit('setUser', userDataResponse.data);
        store.commit('setToken', userDataResponse.data.token);
        this.$router.push('/game-selection')
      } else {
        this.$router.push('/subscribe')
      }
    } else if (autoLoginToken || userToken) {
      this.token = autoLoginToken ? autoLoginToken : userToken;
      const loginResponse = await LoginService.getUserStatus(this.token);
      store.commit('setToken', this.token);
      store.commit('setUser', loginResponse.data);

      if (loginResponse.data.grantAccess) {
        if (loginResponse.data.username) {
          this.$router.push('/game-selection')
        } else {
          this.$router.push('/create-username')
        }
      } else {
        this.$router.push('/subscribe')
      }
    }
  },
  methods: {
    setMiniAppParams(userDataResponse, vodapayUserId) {
      userDataResponse.data.miniAppMode = "portal";
      userDataResponse.data.vodapayUserId = vodapayUserId;
    }
  },
  watch: {
    $route(to, from) {
      if (this.$route.path === '/how-to-play') {
        this.containerScroll = 'scroll'
      } else this.containerScroll = 'hidden';
    }
  }
};
</script>

<style>
Header {
  width: 100vw;
  position: fixed;
  top: 0;
  height: 60px;
}

.content-body {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: v-bind('containerScroll')
}

</style>

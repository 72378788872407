<template>
  <div class="d-flex flex-column align-items-center text-center main-container">
    <div class="d-flex flex-column align-items-center fixed-container col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3">
      <div class="col-9 col-sm-8 col-md-6 col-lg-5 col-xl-10">
        Stand a chance to <strong>WIN AIRTIME</strong> and other <strong>GREAT PRIZES</strong>. Enter your cell number
        below to start playing.
      </div>
      <div class="col-9 col-sm-8 col-md-6 col-lg-5 col-xl-9">
        <input class="mt-2 text-center" type="text" placeholder="Cellphone number" v-model="msisdn"
               @keyup.enter="startPlayingClicked(msisdn)">
      </div>
      <div class="col-9 col-sm-8 col-md-6 col-lg-5 col-xl-9 mt-2 mb-2">
        <button @click="startPlayingClicked(msisdn)" class="text-center start-playing-button">Start Playing</button>
      </div>
      <div v-if="isValidNumber === false" class="pb-1">Invalid number</div>
      <div class="d-flex flex-column align-items-center">
        <div class="secondary-text col-8">
          Pick your team from the best players in<strong> Europe and Africa!</strong>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center">
        <img @click="termsConditionsClicked()" src="../assets/fpb-rating-tag.png" class="col-7 pt-3">
      </div>
    </div>
  </div>
</template>

<script>
import LoginService from "@/services/loginService";
import {mapState, mapMutations} from "vuex";

export default {
  name: "LandingPage",
  data() {
    return {
      isValidNumber: undefined,
    }
  },
  computed: {
    ...mapState(['isLoading'])
  },
  methods: {
    ...mapMutations(['setMsisdn', 'setIsLoading']),
    async startPlayingClicked(msisdn) {
      if (this.isCellNumberValid(msisdn)) {
        const noPlus = msisdn.replace(/[+]/g, "");
        const noZero = noPlus.replace(/^[0]/, "27");
        this.msisdn = noZero.replace(/\s/g, '')
        await LoginService.sendOtp(this.msisdn);
        this.otpSent = true;
        this.setMsisdn(this.msisdn)
        localStorage.setItem('msisdn', this.msisdn);
        this.$router.push('/otp');
      } else {
        this.msisdn = '';
      }
    },
    isCellNumberValid(cellNumber) {
      if (cellNumber.length === 10 || cellNumber.length === 11) {
        let regex = /(?:^[0-9\s]{1,9}?$|(?:0|\+27|27)\d{2}[\s-]?\d{3}[\s-]?\d{4})/;
        if (regex.test(cellNumber)) {
          this.isValidNumber = true;
          return true
        } else {
          this.isValidNumber = false;
          return false
        }
      } else {
        this.isValidNumber = false;
      }
    },
    termsConditionsClicked() {
      location.href = 'https://termsandconditions.vodacomfantasy.com/'
    },
  },
  beforeMount() {
    localStorage.removeItem("token");
    localStorage.removeItem("msisdn");
  }
}
</script>

<style scoped>
.main-container {
  position: fixed;
  top: 55px;
  overflow-y: hidden;
  background-image: url('../assets/onboarding-background.jpg');
  min-height: 100%;
  background-size: 390px 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.fixed-container {
  position: fixed;
  top: 52%;
}

input {
  width: 100%;
  height: 45px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  color: black;
  border: 1px solid #808080;
}

::placeholder {
  font-size: 14px;
  font-weight: 600;
  color: black;
}

.start-playing-button {
  width: 100%;
  height: 45px;
  border: 0;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  background-color: red;
}

</style>

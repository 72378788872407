<template>
  <div class="d-flex flex-column align-items-center text-center main-container">
    <div class="d-flex flex-column align-items-center fixed-container col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3">
      <img class="col-5 mt-2" src="../assets/restricted-icon.png">
      <div class="col-9 mt-2">Your Profile restricts access for this content to be delivered.</div>
      <div class="col-9 mt-3">Contact Customer Care or dial *135*997# to check your content block profile</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentBlock"
}
</script>

<style scoped>
.main-container {
  position: fixed;
  top: 55px;
  overflow-y: hidden;
  background-image: url('../assets/onboarding-background.jpg');
  min-height: 100%;
  background-size: 390px 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.fixed-container {
  position: fixed;
  top: 48%;
}
</style>

import {createStore} from 'vuex'

export default createStore({
    state: {
        isLoading: false,
        showMenuIcon: false,
        token: undefined,
        user: undefined,
        postGresUser: undefined,
        encryptedMsisdn: undefined,
        vodapayUserId: undefined,
        msisdn: undefined,
        promoPopup: undefined,
    },
    mutations: {
        setIsLoading(state, isLoading) {
            state.isLoading = isLoading
        },
        setShowMenuIcon(state, showMenuIcon) {
            state.showMenuIcon = showMenuIcon
        },
        setUser(state, data) {
            state.user = data;
        },
        setToken(state, token) {
            state.token = token;
        },
        setPostgresUser(state, postGresUser) {
            state.postGresUser = postGresUser;
        },
        setEncryptedMsisdn(state, encryptedMsisdn) {
            state.encryptedMsisdn = encryptedMsisdn;
        },
        setVodapayUserId(state, vodapayUserId) {
            state.vodapayUserId = vodapayUserId;
        },
        setMsisdn(state, msisdn) {
            state.msisdn = msisdn;
        },
        setPromoPopup(state, promoPopup) {
            state.promoPopup = promoPopup;
        },
    },
    actions: {},
    modules: {}
})

<template>
  <div class="d-flex flex-column align-items-center container">
    <div class="d-flex flex-column align-items-center sub-container text-center col-10">
      <h6>welcome to motorsport zone</h6>
      <div class="speed-text">FOR THE SPEED ENTHUSIASTS</div>
      <div class="info-text text-center mt-2 col-10">Your go-to platform for thrilling trivia, score predictions, and
        building your dream racing team.
      </div>
      <div class="info-text text-center mt-2 col-10">Join the excitement and connect with fellow fans who live for
        speed!
      </div>
      <button @click="getStartedClicked()" class="mt-2">get started</button>
      <div @click="termsConditionsClicked()" class="term-condition-text mt-1">R5/day. Subscription Service. <span
          class="text-decoration-underline">T&C’s Apply</span></div>
      <div @click="optOutCrossSellPopup()" class="mt-2 ad-text"><span class="fw-bold">Please be advised that this is an ad.</span>
        To stop seeing these
        messages, you can <span class="text-decoration-underline fw-bold">click here</span></div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import DcbService from "@/services/dcbService";
import LoginService from "@/services/loginService";

export default {
  name: "MotorsportCrossSellPopup",
  computed: {
    ...mapState(['encryptedMsisdn', 'user']),
  },
  methods: {
    ...mapMutations(['setPromoPopup']),
    async optOutCrossSellPopup() {
      await LoginService.updatePromoStatus(this.user.msisdn, false);
      this.setPromoPopup(false);
    },
    async getStartedClicked() {
      const campaignId = 122;``
      const {encryptedMsisdn} = (await DcbService.encryptUserMsisdn(this.user.msisdn)).data;
      window.open(`https://motorsportzone.co.za/?e-msisdn=${encryptedMsisdn}&campaignId=${campaignId}`, '_self');
    },
    termsConditionsClicked() {
      window.open('https://motorsportzone.co.za/terms-and-conditions', '', 'width=400, height=400');
    }
  },
}
</script>

<style scoped>
@import url("https://use.typekit.net/khy3mbl.css");

.container {
  position: relative;
  width: 335px;
  height: 585px;
  background-image: url("../../../assets/crossSells/pop-up-cross-sell-motorsport.webp");
  background-size: cover;
}

.sub-container {
  position: absolute;
  top: 335px;
  color: #FFFFFF;
}

h6 {
  font-family: 'ethnocentric';
}

.speed-text {
  font-family: 'termina';
  font-size: 7px;
  color: grey;
}

.info-text, .ad-text, .term-condition-text {
  font-family: 'termina';
  font-size: 9px;
}

button {
  font-family: 'ethnocentric';
  color: #FFFFFF;
  background-color: black;
  border: 0;
  height: 40px;
  border-radius: 18px;
  width: 180px;
  font-size: 15px;
}
</style>
<template>
  <div class="d-flex flex-column align-items-center container">
    <div class="d-flex flex-column align-items-center sub-container text-center col-10">
      <img class="col-11" src="../../../assets/crossSells/prize-banner-cricket.webp">
      <h6 class="header-text mt-2">WELCOME TO CRICKET ZONE</h6>
      <div class="info-text text-center col-11">Your one-stop platform for exciting trivia, cricket predictor, fantasy
        challenges, & the latest cricket news.
        <span
            class="fw-bold" @click="termsConditionsClicked()">R5/day. T&C’s Apply</span>
      </div>
      <button @click="getStartedClicked()" class="d-flex flex-column align-items-center justify-content-center mt-3">GET
        STARTED
      </button>
      <div @click="optOutCrossSellPopup()" class="mt-2 ad-text"><span class="fw-bold">Please be advised that this is an ad.</span>
        To stop seeing these
        messages, you can <span class="text-decoration-underline fw-bold">click here</span></div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import DcbService from "@/services/dcbService";
import LoginService from "@/services/loginService";

export default {
  name: "CricketCrossSellPopup",
  computed: {
    ...mapState(['encryptedMsisdn', 'user']),
  },
  methods: {
    ...mapMutations(['setPromoPopup']),
    async optOutCrossSellPopup() {
      await LoginService.updatePromoStatus(this.user.msisdn, false);
      this.setPromoPopup(false);
    },
    async getStartedClicked() {
      const campaignId = 125;
      const {encryptedMsisdn} = (await DcbService.encryptUserMsisdn(this.user.msisdn)).data;
      window.open(`https://cricketzone.co.za/?e-msisdn=${encryptedMsisdn}&campaignId=${campaignId}`, '_self');
    },
    termsConditionsClicked() {
      window.open('https://cricketzone.co.za/terms-and-conditions', '', 'width=400, height=400');
    }
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400&family=Poppins:wght@400;600;700&display=swap');
</style>

<style scoped>
.info-text, .ad-text, button, .header-text {
  font-family: 'Poppins', Arial, sans-serif;
}

.container {
  margin-top: -15px;
  position: relative;
  width: 335px;
  height: 660px;
  background-image: url("../../../assets/crossSells/pop-up-cross-sell-cricket.webp");
  background-size: cover;
}

.sub-container {
  position: absolute;
  top: 355px;
  color: #FFFFFF;
}

.info-text {
  font-size: 12px;
  line-height: 1.1;
}

.ad-text {
  font-size: 9px;
}

button {
  color: #FFFFFF;
  background-color: #e31616;
  border: 0;
  height: 50px;
  border-radius: 6px;
  width: 225px;
  font-size: 18px;
  font-weight: 600;
}

.header-text {
  font-size: 20px;
  font-weight: 600;
}
</style>
<template>
  <div class="d-flex flex-column align-items-center text-center main-container">
    <div class="d-flex flex-column align-items-center fixed-container col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3">
      <div class="d-flex flex-column align-items-center col-11">
        <div class="create-username-text">
          Create your Username
        </div>
        <div class="secondary-text mt-2 col-11">
          Please enter a <strong>new username</strong> here so we can identify you on our leaderboard.
        </div>
        <div class="secondary-text mt-1">
          Username must be a mixture of <strong>6-14 alphabetical characters</strong>
        </div>
      </div>
      <input class="mt-3 col-9 text-center" type="text" placeholder="Enter username" v-model="username"
             @keyup.enter="submitUsernameClicked(username)">
      <button @click="submitUsernameClicked(username)" class="mt-3 mb-1 col-9 submit-username-button">Submit Username
      </button>
      <button @click="backButtonClicked()" class="mt-1 col-9 back-button">Back</button>
      <div class="col-10 mt-2 username-input-error" v-if="usernameTaken">Error: Username already exists</div>
      <div class="col-10 mt-2 username-input-error" v-if="usernameIncludesProfanity">Error: Invalid Username</div>
      <div class="col-10 mt-2 username-input-error" v-if="usernameLengthShort">Error: Username is less than 6 alphabetical characters</div>
      <div class="col-10 mt-2 username-input-error" v-if="usernameLengthLong">Error: Username is more than 14 alphabetical characters</div>
    </div>
  </div>
</template>

<script>
import LoginService from "@/services/loginService";
import UtilsService from "@/services/utilsService";
import {mapState} from "vuex";

export default {
  name: "CreateUsername",
  data() {
    return {
      usernameTaken: false,
      usernameLengthShort: false,
      usernameLengthLong: false,
      usernameIncludesProfanity: false,
    }
  },
  computed: mapState(['user', 'token']),
  methods: {
    async submitUsernameClicked(username) {
      this.clearUsernameHistory()
      this.checkUsernameCharacterCount(username);
      await this.validateUsername(username)
    },
    clearUsernameHistory() {
      this.usernameTaken = false
      this.usernameLengthShort = false
      this.usernameLengthLong = false
      this.usernameIncludesProfanity = false
    },
    checkUsernameCharacterCount(username) {
      this.usernameLengthShort = username.length < 6;
      this.usernameLengthLong = username.length > 14;
    },
    async validateUsername(username) {
      await this.checkUsernameTaken(username)
      await this.profanityChecker(username);

      if (!this.usernameTaken && !this.usernameLengthShort && !this.usernameLengthLong && !this.usernameIncludesProfanity) {
        await LoginService.updateUsernameSql(username, this.user.msisdn);
        this.$router.push('game-selection');
      }
    },
    async checkUsernameTaken(username) {
      const response = await LoginService.validateUsername(username, this.token);
      this.usernameTaken = !response.data.isValid;
    },
    async profanityChecker(playerUsername) {
      const profanityCheckResponse = await UtilsService.checkUsernameProfanity(playerUsername)
      this.usernameIncludesProfanity = profanityCheckResponse.data.containsProfanity
    },
    backButtonClicked() {
      this.$router.push('/');
    },
  },
}
</script>

<style scoped>
.main-container {
  position: fixed;
  top: 55px;
  overflow-y: hidden;
  background-image: url('../assets/onboarding-background.jpg');
  min-height: 100%;
  background-size: 390px 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.fixed-container {
  position: fixed;
  top: 50%;
}

.create-username-text {
  font-weight: 700;
  font-size: 20px;
}

.secondary-text {
  font-size: 15px;
}

input {
  height: 45px;
  border: 1px solid #808080;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  color: black;
}

::placeholder {
  font-size: 14px;
  font-weight: 600;
  color: black;
}

.submit-username-button {
  height: 45px;
  border: 0;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  background-color: red;
}

.back-button {
  height: 45px;
  border: 2px solid #000000;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  background-color: transparent;
}

.username-input-error {
  color: red;
  font-weight: 700;
}

@media only screen and (max-width: 375px) {
  .fixed-container {
    position: fixed;
    top: 52%;
  }

  .create-username-text {
    font-weight: 700;
    font-size: 17px;
  }

  .secondary-text {
    font-size: 12px;
  }

  input {
    height: 40px;
    border: 1px solid #808080;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 600;
    color: black;
  }

  ::placeholder {
    font-size: 12px;
    font-weight: 600;
    color: black;
  }

  .submit-username-button {
    height: 40px;
    border: 0;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    background-color: red;
  }

  .back-button {
    height: 40px;
    border: 2px solid #000000;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 600;
    color: #000000;
    background-color: transparent;
  }
}

</style>

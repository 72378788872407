<template>
  <div class="d-flex flex-column align-items-center container">
    <div class="d-flex flex-column align-items-center sub-container text-center col-10">
      <img class="col-11" src="../../../assets/crossSells/prize-banner-rugby.webp">
      <h6 class="header-text mt-4">WELCOME TO RUGBY ZONE</h6>
      <div class="info-text text-center col-11">The ultimate rugby experience for exciting trivia, rugby predictor
        & fantasy challenges, & the latest news.
        <span
            class="fw-bold" @click="termsConditionsClicked()">R5/day. T&C’s Apply</span>
      </div>
      <button @click="getStartedClicked()" class="d-flex flex-column align-items-center justify-content-center mt-2">GET STARTED</button>
      <div @click="optOutCrossSellPopup()" class="mt-2 ad-text"><span class="fw-bold">Please be advised that this is an ad.</span>
        To stop seeing these
        messages, you can <span class="text-decoration-underline fw-bold">click here</span></div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import DcbService from "@/services/dcbService";
import LoginService from "@/services/loginService";

export default {
  name: "RugbyCrossSellPopup",
  computed: {
    ...mapState(['encryptedMsisdn', 'user']),
  },
  methods: {
    ...mapMutations(['setPromoPopup']),
    async optOutCrossSellPopup() {
      await LoginService.updatePromoStatus(this.user.msisdn, false);
      this.setPromoPopup(false);
    },
    async getStartedClicked() {
      const campaignId = 124;
      const {encryptedMsisdn} = (await DcbService.encryptUserMsisdn(this.user.msisdn)).data;
      window.open(`https://rugby-zone.co.za/?e-msisdn=${encryptedMsisdn}&campaignId=${campaignId}`, '_self');
    },
    termsConditionsClicked() {
      window.open('https://rugby-zone.co.za/terms-and-conditions', '', 'width=400, height=400');
    }
  },
}
</script>

<style scoped>
@import url("https://use.typekit.net/vho4qgx.css");

.container {
  margin-top: 42px;
  position: relative;
  width: 335px;
  height: 600px;
  background-image: url("../../../assets/crossSells/pop-up-cross-sell-rugby.webp");
  background-size: cover;
}

.sub-container {
  position: absolute;
  top: 270px;
  color: #FFFFFF;
}

h6 {
  font-family: 'alfabet';
}

.info-text {
  font-family: 'alfabet';
  font-size: 12px;
}
.ad-text {
  font-family: 'alfabet';
  font-size: 9px;
}

button {
  font-family: 'alfabet';
  color: #FFFFFF;
  background-color: black;
  border: 0;
  height: 50px;
  border-radius: 28px;
  width: 225px;
  font-size: 21px;
  font-weight: 600;
}

.header-text {
  font-size: 20px;
  font-weight: 600;
}
</style>
import axios from "axios";
import {environmentConfig} from "@/config/environmentConfig";

export default class LoginService {
    static async getUserStatus(token) {
        return await axios.post(`${environmentConfig.upstreamIntegrationService}/get-user-status`, {
            token
        })
    }

    static async updatePromoStatus(msisdn, promoStatus) {
        return await axios.post(`${environmentConfig.upstreamIntegrationService}/update-promo-status`, {
            msisdn,
            promoStatus
        })
    }

    static async autoLogin() {
        try {
            const autoLoginResponse = await axios({
                method: 'get',
                withCredentials: true,
                headers: {device: 'mobi'},
                url: `${environmentConfig.upstreamIntegrationService}/user-authentication/session-login`
            });
            if (autoLoginResponse.data.token) {
                console.log(`autoLoginResponse: ${autoLoginResponse.data}`);
            }
            return autoLoginResponse.data.token;
        } catch (e) {
            const error = {
                name: e.name,
                description: e.message,
                location: 'Automated Login'
            };
            console.log(error)
        }
    }

    static async headerEnrichedLogin(encryptedMsisdn) {
        return await axios.post(`${environmentConfig.upstreamIntegrationService}/user-authentication/encrypted-msisdn-login`, {
            encryptedMsisdn: encryptedMsisdn,
        })
    }

    static async vodapayUserAuth(userId) {
        return await axios({
            method: 'post',
            url: `${environmentConfig.vodaPayIntegrationHost}/vodapay-login`,
            data: {
                vodapayUserId: userId,
            }
        })
    }

    static async otplogin(msisdn, otp) {
        return await axios({
            method: 'post',
            withCredentials: true,
            url: `${environmentConfig.upstreamIntegrationService}/user-authentication/validate-otp`,
            data: {
                msisdn: msisdn,
                otp: otp,
            }
        });
    }


    static async sendOtp(msisdn) {
        return axios({
            method: 'post',
            url: `${environmentConfig.upstreamIntegrationService}/user-authentication/request-otp`,
            data: {
                msisdn: msisdn,
                revenuePartnerId: 1
            }
        })
    }

    static async subscribeDoiTwo(msisdn) {
        return await axios({
            method: 'get',
            url: `${environmentConfig.vodacomIntegratiojnService}/doi-two`,
            params: {
                msisdn: msisdn,
                revenuePartnerId: 1,
                action: 'Subscription',
                channel: 'FantasyLeague-Web'
            }
        })
    }

    static async subscribeDoiOne(msisdn) {
        return await axios({
            method: 'get',
            url: `${environmentConfig.vodacomIntegratiojnService}/doi-one`,
            params: {
                msisdn: msisdn,
                revenuePartnerId: 1,
                action: 'Subscription',
                channel: 'FantasyLeague-Web'
            }
        })
    }

    static async subscribeV2(msisdn, sessionId) {
        return await axios({
            method: 'post',
            url: `${environmentConfig.upstreamIntegrationService}/v2/subscription`,
            data: {
                msisdn: msisdn,
                revenuePartnerId: '1',
                action: 'Subscription',
                sessionId: sessionId
            }
        })
    }

    static async validateUsername(username, userToken) {
        return await axios({
            method: 'post',
            url: `${environmentConfig.upstreamIntegrationService}/user-authentication/validate-username`,
            data: {
                username: username,
                userToken: userToken
            }
        })
    }

    static async updateUsernameSql(username, msisdn) {
        return await axios({
            method: 'post',
            url: `${environmentConfig.upstreamIntegrationService}/updateUserName`,
            data: {
                msisdn: msisdn,
                username: username,
            }
        })
    }
}

<template>
  <div class="d-flex flex-column justify-content-center align-items-center  main-container">
    <img src="../assets/main-fantasy-header.png" class="fantasy-header-img">
  </div>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>
.main-container {
  background-color: #FFFFFF;
  height: 60px;
}

.fantasy-header-img {
  width: 300px;
}

</style>

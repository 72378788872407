<template>
  <div class="d-flex flex-column align-items-center text-center main-container">
    <div class="d-flex flex-column align-items-center text-center fixed-container" v-if="!subscribeError">
      <div class="mt-4 col-10 smaller-text">
        <div>I confirm my subscription of R3.00/day (or a lesser amount).</div>
        <div>This can be later managed with your profile settings.</div>
      </div>
      <button @click="onConfirmSubscription()"
              class="mt-3 col-9 text-center subscribe-button">Confirm
      </button>
      <div class="mt-3 col-9 col-sm-8 col-md-8 col-lg-8 col-xl-8 terms-conditions-text">
        <p>By clicking 'Confirm' I agree to the<a href="https://termsandconditions.vodacomfantasy.com/"><u> Terms and
          Conditions</u></a> of Vodacom Fantasy</p>
      </div>
    </div>

    <div class="d-flex flex-column align-items-center text-center fixed-container" v-if="subscribeError">
      <div class="mt-4 col-10 smaller-text">
        <div>There was an error processing your subscription. Please re-login to try again.</div>
      </div>
      <button @click="onRedirectToLogin()"
              class="mt-5 col-9 col-sm-8 col-md-8 col-lg-8 col-xl-8 text-center subscribe-button">Go To Login Page
      </button>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";
import LoginService from "@/services/loginService";

export default {
  name: "SubscribeUser",
  data() {
    return {
      isSubscriptionConfirmed: false,
      subscribeError: false
    }
  },
  computed: mapState(['user', 'token']),
  methods: {
    ...mapMutations(['setUser']),
    async onConfirmSubscription() {
      await LoginService.subscribeDoiTwo(this.user.msisdn);
      const response = await LoginService.subscribeV2(this.user.msisdn, this.token);

      if (response.data.result === true) {
        const subscribedUser = await LoginService.getUserStatus(this.user.token)
        this.setUser(subscribedUser.data)
        this.user.username ? this.$router.push('game-selection') : this.$router.push('create-username');
      } else if (response.data.error === 'CNT_BLOCKED') {
        this.$router.push('/content-block');
      } else if (response.data.error === 'subscriptionFailed') {
        this.isSubscriptionConfirmed = false;
        this.subscribeError = true
      }
    },
    async onRedirectToLogin() {
      this.$router.push('/login');
    }
  },
}
</script>

<style scoped>
.main-container {
  position: fixed;
  top: 55px;
  overflow-y: hidden;
  background-image: url('../assets/onboarding-background.jpg');
  min-height: 100%;
  background-size: 400px 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.fixed-container {
  position: fixed;
  top: 50%;
}

.smaller-text {
  font-size: 14px;
}

.subscribe-button {
  height: 50px;
  border: 0;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
  color: white;
  background-color: red;
}

.terms-conditions-text {
  font-size: 12px;
}

@media only screen and (max-width: 400px) {
  .fixed-container {
    position: fixed;
    top: 51%;
  }

}
</style>

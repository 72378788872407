import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css"

createApp(App).use(store).use(router).mount('#app')

Array.prototype.asyncForEach = async function(callback, thisArg) {
    thisArg = thisArg || this;
    for (let i = 0, l = this.length; i !== l; ++i) {
        await callback.call(thisArg, this[i], i, this)
    }
};

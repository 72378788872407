<template>
  <div class="d-flex flex-column align-items-center text-center main-container">
    <div class="d-flex flex-column align-items-center fixed-container col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3">
      <div class="col-9 col-sm-8 col-md-6 col-lg-5 col-xl-9">
        <input class="col-9 col-sm-8 col-md-8 col-lg-8 col-xl-8 mt-5 text-center" type="text" placeholder="Enter OTP"
               v-model="otp" @keyup.enter="confirmOTP(otp)">
      </div>
      <div class="col-9 col-sm-8 col-md-6 col-lg-5 col-xl-9 mt-3">
        <button @click="confirmOTP(otp)"
                class="start-playing-button col-9 col-sm-8 col-md-8 col-lg-8 col-xl-8 text-center">Start Playing
        </button>
      </div>
      <div class="mt-3">
        <div class="otp-text">I did not receive my OTP</div>
        <div @click="resendOTPClicked()" class="resend-otp-text">Resend OTP</div>
      </div>
      <div class="d-flex flex-column align-items-center error-messages">
        <div class="pt-2 col-9" v-if="isOtpExpired">
          Your OTP has expired, please click "Resend OTP" to receive a new otp.
        </div>
        <div class="pt-2 col-9" v-if="isOtpCountExceeded">
          You have made two incorrect attempts, please click "Resend OTP" to receive a new otp.
        </div>
        <div class="pt-2" v-if="!isOtpValid">
          You entered your OTP incorrectly
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import LoginService from "@/services/loginService";

export default {
  name: "SubmitOTP",
  computed: mapState(['msisdn']),
  data() {
    return {
      isOtpValid: true,
      isOtpExpired: false,
      isOtpCountExceeded: false,
    }
  },
  methods: {
    ...mapMutations(['setUser', 'setToken', 'setPostgresUser', 'setIsLoading']),
    async confirmOTP(otp) {
      try {
        const revenuePartnerId = 1;
        if (otp) {
          const otpResponse = await LoginService.otplogin(this.msisdn, otp, revenuePartnerId);

          if (otpResponse.data.token) {
            const user = await LoginService.getUserStatus(otpResponse.data.token);
            this.setToken(user.data.token);
            this.setUser(user.data);
            localStorage.setItem('token', user.data.token);
            this.$router.push('game-selection');
          }
          if (otpResponse.data.otpAttemptCount) {
            if (otpResponse.data.otpAttemptCount === 1) this.isOtpValid = false;
            if (otpResponse.data.otpAttemptCount > 1) {
              this.isOtpCountExceeded = true;
              this.isOtpValid = true;
            }
          }
          if (otpResponse.data.expiredOTP) {
            this.isOtpValid = true;
            this.isOtpExpired = true;
          }
        } else {
          this.isOtpValid = false;
        }
      } catch (e) {
        const error = {
          name: e.name,
          description: e.message
        };
        this.isOtpValid = false;
      }
    }
    ,
    async resendOTPClicked() {
      this.otp = '';
      this.isOtpValid = true;
      await LoginService.sendOtp(this.msisdn);
      this.isOtpExpired = false;
      this.isOtpCountExceeded = false;
    }
  }
}
</script>

<style scoped>
.main-container {
  position: fixed;
  top: 55px;
  overflow-y: hidden;
  background-image: url('../assets/onboarding-background.jpg');
  min-height: 100%;
  background-size: 390px 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.fixed-container {
  position: fixed;
  top: 50%;
}

input {
  width: 100%;
  height: 45px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  color: black;
  border: 1px solid #808080;
}

::placeholder {
  font-size: 14px;
  font-weight: 600;
  color: black;
}

.otp-text, .resend-otp-text {
}

.resend-otp-text {
  text-decoration: underline;
}

.start-playing-button {
  width: 100%;
  height: 45px;
  border: 0;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  background-color: red;
}

</style>

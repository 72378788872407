import {createRouter, createWebHistory} from 'vue-router'
import LandingPage from "@/views/LandingPage";
import SubmitOTP from "@/views/SubmitOTP";
import CreateUsername from "@/views/CreateUsername";
import GameSelection from "@/views/GameSelection";
import SubscribeUser from "@/views/SubscribeUser";
import HowToPlay from "@/views/HowToPlay";
import ContentBlock from "@/views/ContentBlock";
import store from "@/store";

const routes = [
    {
        path: '/',
        redirect: '/login',
    },
    {
        path: '/login',
        name: 'Login',
        component: LandingPage,
    },
    {
        path: '/otp',
        name: 'SubmitOTP',
        component: SubmitOTP,
        beforeEnter: routerGuard,
    },
    {
        path: '/subscribe',
        name: 'SubscribeUser',
        component: SubscribeUser,
        beforeEnter: routerGuard,
    },
    {
        path: '/create-username',
        name: 'CreateUsername',
        component: CreateUsername,
        beforeEnter: routerGuard,
    },
    {
        path: '/game-selection',
        name: 'GameSelection',
        component: GameSelection,
        beforeEnter: routerGuard,
    },
    {
        path: '/how-to-play',
        name: 'HowToPlay',
        component: HowToPlay,
        beforeEnter: routerGuard,
    },
    {
        path: '/content-block',
        name: 'ContentBlock',
        component: ContentBlock,
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

async function routerGuard(to, from, next) {
    let isAuthenticated = false;

    if (to.fullPath === '/otp') {
        const msisdn = localStorage.getItem('msisdn');
        store.commit('setMsisdn', msisdn);
        if (store.state.msisdn) {
            isAuthenticated = true;
        }
    }
    if (to.fullPath === '/game-selection') {
        const token = localStorage.getItem('token');
        if (token) store.commit('setToken', token);

        if (store.state.encryptedMsisdn || store.state.token || store.state.user) {
            isAuthenticated = true;
        }
    }
    if (to.fullPath === '/subscribe') {
        if (store.state.user && store.state.user.status !== 'Subscribed') {
            isAuthenticated = true;
        }
    }
    if (to.fullPath === '/create-username' || to.fullPath === '/how-to-play') {
        if (store.state.user || store.state.token) {
            isAuthenticated = true;
        }
    }
    if (isAuthenticated) {
        next();
    } else {
        next('/');
    }
}

router.beforeEach((to, from, next) => {
    const utagData = {
        page_name: window.location.host,
        page_section: to.fullPath,
        page_category: '',
        page_subcategory: '',
        page_type: 'Article',
        page_title: document.title,
        page_url: window.location.href,
        page_referrer: document.referrer,
        customer_id: '',
        customer_username: '',
        customer_country: window.navigator.language.split('-').reverse()[0],
        site_region: window.navigator.language,
        article_id: '',
        article_name: '',
        video_id: '',
        video_name: '',
        video_player: '',
        user_platform: window.clientInformation.platform,
        // connection_type: window.clientInformation.connection.effectiveType,
        team_id: '',
        page_event: '',
        ad_requests: '',
        page_channel: '',
        page_platform: '',
        am_report_suite: '',
        page_section_lvl1: '',
        page_section_lvl2: '',
        page_section_lvl3: '',
        page_section_lvl4: '',
        page_section_lvl5: ''
    };

    console.log('utag_data: ', utagData);
    const a = 'https://tags.tiqcdn.com/utag/vodafone/za-nxtvl-main/prod/utag.js';
    const b = document;
    const c = 'script';
    const d = b.createElement(c);
    d.src = a;
    d.type = 'text/java' + c;
    d.async = true;
    const e = b.getElementsByTagName(c)[0];
    e.parentNode.insertBefore(d, e);
    window.utag_data = utagData;

    next();
})

export default router
